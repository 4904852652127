import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, PageProps } from "gatsby"
import { useLogos } from "hooks/queries/settings/useLogos"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { appendScript } from "assets/js/helper"
import { EMPTY_PAGE } from "assets/js/EmptyPage"
import Layout from "components/layout"
import SEO from "components/seo/seo"
import ContentBlocks from "components/content-blocks/ContentBlocks"

type PageContext = PageProps["pageContext"] & {
  slug: string
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  contentUrlPathCodex: Record<string, string>
  linkedPagesUrlPathCodex: Record<string, string>
}

interface LoginPageProps extends PageProps {
  pageContext: PageContext
}

const LoginPage: React.FC<LoginPageProps> = ({ location, pageContext }) => {
  const { pathname, hash } = location
  const { contentUrlPathCodex, slug, linkedPagesUrlPathCodex } = pageContext

  const data = useStaticQuery(getLoginData)

  const [page] = data.allStrapiPage.nodes

  const { meta_data, content } = page ?? EMPTY_PAGE
  const { companyName } = useGeneralSettings()
  const { headerLogo, sideNavLogo } = useLogos()
  const loginMessage = "Your Username / Password is incorrect"

  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [subDomain, setsubDomain] = useState("")
  const [isRender, setIsRender] = useState(false)

  const logo = sideNavLogo?.url ? sideNavLogo : headerLogo
  const windowAvailable = typeof window !== "undefined"

  /* eslint-disable */
  const handleSubmit = e => {
    e.preventDefault()
    let auth = PestportalsClient.getAuthenticatedPortalLink(user, password)
    auth.then(response => {
      if (response.success) {
        document.location.href = response.loginLink
      } else {
        windowAvailable && window.location.replace("/login?error=invalid")
      }
    })
  }

  const script = () => {
    !window.jQuery &&
      appendScript({
        id: "jquery-3.6.0.min.js",
        scriptToAppend: "https://code.jquery.com/jquery-3.6.0.min.js",
        isAsync: false,
      })

    if (window.jQuery && typeof PestportalsClient !== "object")
      appendScript({
        id: "pestportalsClient.js",
        scriptToAppend:
          "https://lmk.pestroutes.com/resources/js/lobster/pestportalsClient.js",
        isAsync: true,
      })
    else setTimeout(script, 200)
  }

  useEffect(() => {
    if (isRender) {
      script()
      fetch("https://lmk.pestroutes.com/lobster/getConnectedOffices")
        .then(response => response.json())
        .then(r => {
          setsubDomain(Object.keys(r.connectedOffices.dbOffices)[0])
        })
    } else {
      setIsRender(true)
    }
  }, [isRender])

  return (
    <Layout
      contentUrlPathCodex={contentUrlPathCodex}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
      location={slug}
      pathName={pathname}
    >
      <SEO
        title={meta_data?.meta_title ?? ""}
        description={meta_data?.meta_description ?? ""}
        noIndex={true}
      />
      <section className="loginHero">
        <div className="container loginSection">
          <div className="welcome-wrapper">
            Thank you for choosing <br />
            <strong className="company_name">{companyName}</strong>
          </div>

          <div className="loginWrapper">
            <div className="loginFormWrapper">
              <div className="pp-text-center">
                {logo && <img src={logo.url} alt={logo.alternativeText} />}
              </div>
              <form id="customLoginForm" onSubmit={handleSubmit}>
                <label htmlFor="accountNumber">
                  <span>Account Number</span>
                </label>
                <input
                  value={user}
                  name="accountNumber"
                  placeholder="Account Number"
                  type="text"
                  onChange={e => setUser(e.target.value)}
                />

                <label htmlFor="password">
                  <span>Password</span>
                </label>
                <input
                  value={password}
                  name="password"
                  placeholder="password"
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                />
                <div className="login-subtext">
                  Don't know your password?{" "}
                  <a
                    href={`https://${subDomain}.pestportals.com/forgotPassword`}
                    target="_blank"
                    id="forgotpass"
                  >
                    Get Login Info
                  </a>
                </div>
                <div className="pp-text-center">
                  <button type="submit" className="login-button">
                    Login
                  </button>
                  <div id="loginMessage">
                    {windowAvailable &&
                      window.location.href.includes("?error=invalid") &&
                      loginMessage}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="info-section">
        <div className="pp-info">
          <div className="pp-info-header pp-text-right">
            <strong>Login</strong> to manage your account
          </div>

          <div className="container-colored-line-two">
            <hr className="colored-line-two" />
            <div className="add-colored-line-two"></div>
          </div>
          <div className="container-item pp-text-right">
            <div className="item">
              <div className="icon icon-thumbsup"></div>
              <p className="item-description wow">
                <strong>Manage Billing</strong>
                <br />
                Update credit card information, make payments, and see
                transaction history. <br />
              </p>
            </div>

            <div className="item">
              <div className="icon icon-heart"></div>
              <p className="item-description">
                <strong>View Service History</strong>
                <br />
                See detailed history, leave feedback for technicians, and print
                service notifications.
              </p>
            </div>

            <div className="item">
              <div className="icon icon-info"></div>
              <p className="item-description">
                <strong>Update Info</strong>
                <br />
                Update basic account info and set communication preferences.
              </p>
            </div>
          </div>
        </div>

        <div className="pp-image pp-text-right">
          {content && <ContentBlocks strapiContent={content} />}
        </div>
      </section>
    </Layout>
  )
}

const getLoginData = graphql`
  query GetLoginData {
    allStrapiPage(filter: { slug: { eq: "login" } }) {
      nodes {
        content {
          ...StrapiSingleImageFragment
        }
        meta_data {
          meta_title
          meta_description
          canonical_link
        }
      }
    }
  }
`

export default LoginPage
